@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  @apply bg-slate-100;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.custom-bw-effect {
  filter: contrast(0.8) brightness(1.2) grayscale(1);
  transition: filter 0.3s ease;
}

.custom-bw-effect:hover {
  filter: contrast(1) brightness(1) grayscale(0);
}

.product-card {
  position: relative;
  /* Ensure other styles are unaffected by grayscale */
}

.fixed-modal {
  position: fixed;
  inset: 0;
  z-index: 9999; /* Ensure it's on top */
}